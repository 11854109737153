import NewFooter from '../NewFooter/NewFooter';
import ProjectLogo from '../ProjectLogo/ProjectLogo';
import { ESolFooterProps } from './types';

const ESolFooter = ({ skewOverlap }: ESolFooterProps) => {
  return (
    <NewFooter
      copyrightText="enercitySolution GmbH"
      foundationSlot={
        <>
          <NewFooter.Link href="/impressum">Impressum</NewFooter.Link>
          <NewFooter.Link href="/datenschutz">Datenschutz</NewFooter.Link>
          <NewFooter.Link href="/seitenuebersicht">
            Seitenübersicht
          </NewFooter.Link>
        </>
      }
      logoSlot={<ProjectLogo height={54} project="esol" solidFill />}
      skewOverlap={skewOverlap}
    />
  );
};

export default ESolFooter;
