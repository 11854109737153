import ComponentList from '@uikit/components/ComponentList/ComponentList';
import Container from '@uikit/components/Container/Container';
import ESolFooter from '@uikit/components/ESolFooter/ESolFooter';
import ESolHeader from '@uikit/components/ESolHeader/ESolHeader';
import Headline from '@uikit/components/Headline/Headline';
import IframeSection from '@uikit/components/IframeSection/IframeSection';
import ImageGallery from '@uikit/components/ImageGallery/ImageGallery';
import Modal from '@uikit/components/Modal/Modal';
import Section from '@uikit/components/Section/Section';
import SectionVisual from '@uikit/components/SectionVisual/SectionVisual';
import StageBoxText from '@uikit/components/StageBoxText/StageBoxText';
import TeaserWithImage from '@uikit/components/TeaserWithImage/TeaserWithImage';
import TwoColumnContainer from '@uikit/components/TwoColumnContainer/TwoColumnContainer';
import HeroArea from '@uikit/composites/HeroArea/HeroArea';
import HeroAreaContent from '@uikit/composites/HeroArea/HeroAreaContent';
import SmallHeroArea from '@uikit/composites/SmallHeroArea/SmallHeroArea';
import React from 'react';
import Forms from '../components/Forms/Forms';

export const CmsComponentsESol = {
  'esol-footer': ESolFooter,
  'esol-header': ESolHeader,
  'esol-stage-box-text': StageBoxText,
  'esol-section': Section,
  'esol-container': Container,
  'esol-stage-small': SmallHeroArea,
  'esol-hero-area-content': HeroAreaContent,
  'esol-stage': HeroArea,
  'esol-headline': Headline,
  'esol-stage-area': React.Fragment,
  'esol-section-visual': SectionVisual,
  'esol-two-column-container': TwoColumnContainer,
  'esol-modal': Modal,
  'esol-iframe-section': IframeSection,
  'esol-image-gallery': ImageGallery,
  'esol-download-area': React.Fragment,
  'esol-contact-area': React.Fragment,
  'esol-component-list': ComponentList,
  'esol-forms': Forms,
  'esol-teaser-with-image': TeaserWithImage,
};
